// HKTDC CMS Template Default Style

// DO NOT EDIT this file

@charset "utf-8";

.mainContent {
  margin: 0;
  background-color: $colorMainContentDefaultBg;
  min-height: 300px;

  .sec-intro {
    text-align: center;
    padding: 25px 0;
    background-color: white;
    + .container {
      padding: $spaceIntroContainer;
      iframe {
        max-width: 960px;

        @media screen and (max-width: $max-w-mb) {
          width: 100%;
        }
      }
    }
  }
  .sec-intro__title {
    opacity: 0;
    @include animation(sec-intro-fadetop 0.6s);

    .is-firefox & {
      // Churix, PHR-288, to change firefox to solid color title
      background: none;
      color: $colorDefault;
      @include animation(sec-intro-fadetop 1s);
    }

    display: block;
    //@extend .title-xl;
    @extend .title-lv2;
    max-width: 800px;
    font-weight: 200;
    margin: 0 auto;
    background: $colorIntroTitleBg1;
    background: -moz-linear-gradient(
      left,
      rgba($colorIntroTitleBg1, 1) 0%,
      rgba($colorIntroTitleBg2, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba($colorIntroTitleBg1, 1) 0%,
      rgba($colorIntroTitleBg2, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba($colorIntroTitleBg1, 1) 0%,
      rgba($colorIntroTitleBg2, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorIntroTitleBg1}', endColorstr='#{$colorIntroTitleBg2}', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;

    @media screen and (min-width: 1581px) {
      //@include fontSize(60);
    }

    .is-ie & {
      background: transparent;
      color: $colorIntroTitleTxt;
    }
  }

  @include keyframes(sec-intro-fadein) {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @include keyframes(sec-intro-fadetop) {
    0% {
      opacity: 0;
      @include transform(translateY(30px));
    }

    100% {
      opacity: 1;
      @include transform(translateY(0));
    }
  }
}

.header {
  &__top {
    text-align: right;
    font-size: 13px;
    padding: 10px 0;
    white-space: nowrap;
    color: $colorGrey;
    .languages,
    .header-bar {
      display: inline;

      a {
        color: $colorGrey;
        padding: 0 14px;
        display: inline-block;
        vertical-align: middle;
        z-index: 10;
        position: relative;
      }
    }
    .languages {
      position: relative;
      &.mobileHiddenBar {
        &:before {
          @media screen and (max-width: 992px) {
            width: 0;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 15px;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: $colorGrey;
      }
      a:last-child {
        padding: 0 3px;
      }

      a:first-child {
        padding: 0 3px;
        margin-right: 5px;
      }
    }
    .header-bar {
      a {
        position: relative;
        &:first-child {
          &:before {
            width: 0px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 1px;
          height: 15px;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background: $colorGrey;
        }
      }
    }
  }
}

.show > .nav-dropdown.btn-secondary.dropdown-toggle {
  box-shadow: none;
  background: none;
}

header {
  @media screen and (min-width: 1200px),
    screen and (min-width: 992px),
    screen and (min-width: 768px),
    screen and (min-width: 576px) {
    .container {
      max-width: 1600px;
      // padding: 0;
    }
  }
  button.btn-secondary:not(.nav-dropdown) {
    margin-top: -4px;
  }
  button.nav-dropdown.btn-secondary {
    background: none;
    border: none;
    font-size: 14px;
    padding: 0;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
    &::after {
      color: $colorTxt;
    }
    > a.nav-link {
      display: inline-block;
    }
    > span.nav-link {
      display: inline-block;
    }
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      box-shadow: none;
      background: none;
      &::after {
        color: $colorDefaultTxt;
      }
    }
  }
  .navbar {
    .dropdown:hover {
      button > .nav-link {
        opacity: 1;
      }
    }
    .dropdown-menu {
      background-color: $colorDefault;
      border: none;
      margin: 0;
      border-radius: 0;
      @include non-tablet {
        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          top: -5px;
          left: calc(50% - 10px);
          transform: rotate(45deg);
          background: $colorDefault;
        }
      }
      @include non-tablet {
        -webkit-transform: translate(-30%, 0);
        -o-transform: translate(-30%, 0);
        transform: translate(-30%, 0);
      }
      .nav-link {
        padding: 10px 30px;
        white-space: nowrap;
        color: rgba(255, 255, 255, 1);
        font-weight: normal;
        font-size: 14px;
        &.active {
          font-weight: bold;
          color: white;
        }
      }
    }
    .mainLogo {
      height: 60px;
    }
    .navbar-nav {
      > .nav-link {
        padding-left: 0;
        padding-right: 0;
        color: $colorTxt;
        text-transform: uppercase;
        &:hover {
          color: $colorDefaultTxt;
        }
      }
      @media screen and (min-width: 992px) {
        align-items: center;
        justify-content: center;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 0;
    }
  }
  .nav-item {
    margin-left: 30px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
    > .nav-link {
      text-transform: uppercase;
      margin: 0 auto 5px;
    }
  }
  .nav-link {
    font-weight: bold;
    font-size: 20px;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 991px) {
    .navbar-collapse {
      margin-top: 20px;
    }
  }
}

.navbar-light {
  .navbar-nav .dropdown-menu .nav-link {
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .navbar-nav .dropdown-menu.show > .nav-link {
    color: white;
  }
}

/*  menu toggler */
/* remove navbar button stying */
.navbar-toggler {
  background: none;
  border: none;

  /* remove the blue outline when active or focused */
  &:active,
  &:focus {
    outline: 0;
  }

  /* basic styles for each icon bar */
  .icon-bar {
    background: black;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;

    /* .navbar open top .icon-bar rotated down 45° */
    &:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    /* .navbar open middle .icon-bar invisible */
    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity=0);
    }

    /* .navbar open bottom .icon-bar rotated up 45° */
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }

  /* styles for when .navbar is closed */
  &.collapsed {
    .icon-bar {
      /* .navbar closed top .icon-bar no rotation - straight */
      &:nth-of-type(1) {
        transform: rotate(0);
      }

      /* .navbar open middle .icon-bar visible */
      &:nth-of-type(2) {
        opacity: 1;
        filter: alpha(opacity=100);
      }

      /* .navbar open bottom .icon-bar no rotation - straight */
      &:nth-of-type(3) {
        transform: rotate(0);
      }
    }
  }
}

/* PAGINATION */
.pagination {
  margin-top: 40px;
  font-weight: 700;
  justify-content: center;
  .page-item {
    .page-link {
      color: $colorTxt;
      background: none;
      border: none;
      padding: 0.5rem 0.3rem;
      display: inline;
      vertical-align: middle;
      &-btn {
        border: 1px solid $colorDefaultBorder;
        color: $colorDefaultTxt;
        border-radius: 30px;
        box-sizing: border-box;
        padding: 12px 40px;
        margin: 0;
        cursor: pointer;
        margin: 0 1.25rem;
        &:hover {
          background: $colorDefaultBg;
          color: #fff;
          opacity: 1;
        }
      }
    }
    &.active {
      .page-link {
        color: $colorDefaultTxt;
      }
    }
  }
}

/* LIST GROUP */
.list-group {
  .list-group-item {
    border-radius: none;
    padding: 1.75rem 0;
    border: 1px solid hsla(0, 0%, 74.5%, 0.2);
    border-left: none;
    border-right: none;
    a {
      color: $colorTxt;
      display: block;
      // float: right;
      // @extend #{'.btn', '.btn-secondary'};
    }
    a,
    a:active,
    a:hover {
      text-decoration: none;
      color: $colorDefaultTxt;
    }
  }
}

/* Card Style */
.card-title {
  font-size: 20px;
  font-weight: 700;
}
.card-text {
  > div {
    display: inline;
  }
  a {
    text-decoration: underline;
    color: $colorDefaultTxt;
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: inline;
    padding-left: 5px;
  }
}

/* Bootstrap Button style */
.btn-primary {
  border: 1px solid $colorDefaultBorder;
  color: white;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 10px 35px;
  margin: 0;
  cursor: pointer;
  margin: 0;
  background-color: $colorDefaultBorder;
  &:hover {
    border-color: $colorDefaultTxt;
    background: $colorDefaultBg;
    color: #fff;
    opacity: 0.9;
  }
  &:not(:disabled):not(.disabled):active {
    border-color: $colorDefaultTxt;
    background: $colorDefaultBg;
    color: #fff;
  }
}
.btn-secondary {
  border: 1px solid $colorDefaultBorder;
  color: $colorDefaultTxt;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 12px 40px;
  margin: 0;
  cursor: pointer;
  margin: 0;
  background-color: transparent;
  &:not(:disabled):not(.disabled):hover {
    border-color: $colorDefaultTxt;
    background: $colorDefaultBg;
    color: #fff;
    opacity: 1;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $colorDefaultTxt;
    background: $colorDefaultBg;
    color: #fff;
  }
}


/* INDEX PAGE */
.indexPage {
  background-color: #fff;
  .mainContent & {
    .container {
      padding-top: 0;
    }
  }
}

/* SPEAKER PAGE */
.speakers {
  &__title {
    color: $colorHightLight;
    margin-bottom: 1.5em;
  }
}

.logoContainer {
  .banner {
    display: none;
  }
}

@include non-tablet {
  .headerStyle-2 {
    flex-direction: column;
    .logoContainer {
      display: flex;
      align-self: flex-start;
      .logo {
        display: flex;
        align-items: center;
      }
      .banner {
        display: block;
        img {
          max-height: 100px;
        }
      }
    }
  }
}

@include tablet {
  .headerStyle-2 {
    .logoContainer {
      .banner {
        display: none;
      }
    }
  }
}

.gallery {
  &__item {
    &__wrapper {
      background-color: #fff;
      border: 1px solid #d7d7d7;
      border-radius: 0.325em;
      padding: 0.7em;
    }
    &__buttons {
      margin-top: 0.7em;
      .btn {
        margin-right: 0.7em;
      }
      .btn-sm {
        padding: 0.25em 0.7em;
        border-radius: 0.325em;
      }
    }
    &__img {
      margin-bottom: $spaceListItem * 0.5;
    }
    &__detail {
      &-height {
        min-height: $spaceListItem * 5.5;
      }
      &__title {
        color: $colorDefaultTxt;
        font-weight: bold;
        padding-bottom: $spaceListItem;
        font-size: $sizeListItemTitleTxt * 0.75;
      }
      &__desc {
        font-size: $sizeListItemTitleTxt * 0.5;
      }
      &__date {
        font-size: $sizeListItemTitleTxt * 0.5;
      }
    }
  }

  &__popup {
    flex-grow: 1;
    &__video {
      margin-bottom: $spaceListItem;
    }
    &__title {
      color: $colorDefaultTxt;
      font-weight: 600;
      font-size: $sizeListItemTitleTxt;
      margin-bottom: $spaceListItem * 0.5;
    }
    &__text {
      margin-bottom: $spaceListItem;
      color: #5a5b63;
      text-align: left;
      // padding-left: $spaceListItem * 1.5;
      // text-indent: -$spaceListItem * 1.5;
      // &::before {
      //   color: #fc5e08;
      //   padding-right: $spaceListItem * 0.5;
      //   content: '\25ba';
      // }
    }
  }
}

.access-elem {
  position: absolute;
  left: 0px;
  top: -5000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Widget Slider -- Start */
.widget-slider.slider-container {
  h3 {
    color: $colorDefault;
    text-align: center;
    margin: 20px 0 40px;
  }

  h5 {
    margin: 20px 0;
  }

  p > a {
    text-decoration: underline;
    color: $colorDefault;
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
  }

  .widget-slider-container__slider-pause {
    display: inline-block;
    position: absolute;
    right: 35px;
    bottom: 30px;
    z-index: 10;

    .pause-button {
      display: block;
      box-sizing: border-box;
      width: 0;
      height: 15px;
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;
      cursor: pointer;
      border: 0 double transparent;
      border-left: 15px double #1a1a1a;
    }

    &.is-active .pause-button {
      border-style: solid;
      border-width: 8px 0 8px 12px;
    }
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 3px;
    border-radius: 0;

    &-active {
      background: $colorDefault;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    padding: 0 60px 50px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    &__content {
      display: block;
      width: 100%;
    }
  }
}

/* Widget Slider -- END */

/* Mobile style 2 -- START */
.navbar .languages a {
  margin-right: 14px;
}

.nav-item {
  .header-bar {
    margin-bottom: 1rem;
    &:last-child {
      margin: 0;
    }
  }
}

/* Mobile style 2 -- END */

/* AdGlare */
.agZone {
  text-align: center;
  margin-bottom: 60px;
  span {
    margin: 4px;
    display: inline-block;
  }
}
/* AdGlare END */
/* SSO icons */
.top-bar__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  $size: 17px;
  width: $size;
  height: $size;
  background: url('') no-repeat center center;
  background-size: 90%;
  opacity: 0.6;

  &.top-bar__icon--login {
    cursor: default;
    background-image: imgurl('common/icons/svg/black/icon-profile.svg');
  }

  &.top-bar__icon--share {
    background-image: imgurl('common/icons/svg/black/icon-share.svg');
  }
}
/* SSO Icons end */

/* rc-menu Mobile Menu */
.ct-mobileMenu {
  @media screen and (min-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 991px) {
    &.rc-menu-inline {
      border: none;
      box-shadow: none;
      border-radius: 0;
      padding: 0;

      .nav-item {
        margin: 0;
      }

      > .rc-menu-item {
        margin-left: 30px;
      }

      > .rc-menu-submenu {
        > .rc-menu-submenu-title {
          margin-left: 30px;
          &:hover .rc-menu-submenu-arrow {
            color: $colorDefault;
          }
        }
      }
      .rc-menu-sub {
        &.rc-menu-inline {
          padding-top: 5px;
          background-color: $colorDefault;
          margin-left: 30px;
          margin-top: 8px;
          .rc-menu-item {
            padding-left: 2px !important;
            a {
              color: #fff;
              font-weight: normal;
              text-transform: none;
            }
          }
          .rc-menu-item-active a {
            font-weight: bold;
          }
          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        }
      }

      .rc-menu-submenu-arrow {
        margin-left: 10px;
        position: relative;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ct-mobileMenu.rc-menu-inline > .rc-menu-item,
  .ct-mobileMenu.rc-menu-inline .rc-menu-item .nav-link,
  .ct-mobileMenu.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 0 !important;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
  }

  .ct-mobileMenu.rc-menu-inline > .rc-menu-item,
  .ct-mobileMenu .rc-menu-submenu {
    padding: 8px 0 !important;
  }

  .ct-mobileMenu .rc-menu-item-active,
  .ct-mobileMenu.rc-menu-inline > .rc-menu-item:hover,
  .ct-mobileMenu.rc-menu-inline > .rc-menu-item .nav-link:hover,
  .ct-mobileMenu.rc-menu-inline .rc-menu-item .nav-link:hover,
  .ct-mobileMenu .rc-menu-submenu-active > .rc-menu-submenu-title,
  .ct-mobileMenu.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title:hover,
  .ct-mobileMenu .rc-menu-item-selected {
    background: none;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .ct-mobileMenu.rc-menu-inline > .rc-menu-item,
  .ct-mobileMenu.rc-menu-inline .rc-menu-item .nav-link,
  .ct-mobileMenu.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    margin: 0;
  }
}
