@charset "utf-8";

//Color
$colorBlack-revamp: #263238;
$colorDGrey-revamp: #4d4d4d;
$colorGrey-revamp: #717171;
$colorLGrey-revamp: #89939e;
$colorGreyBlue-revamp: #abbed1;
$colorSilver-revamp: #f5f7fa;
$colorWhite-revamp: #ffffff;

//ThemeColor
$themeShowcase: #fdb22e;
$themeStories: #e06527;
$themeHappenings: #d93b84;

//Typography
h1,
h2,
h3,
h4,
h5,
h6,
h7,
label,
input,
select,
textarea,
a,
label,
table {
  font-family: 'Roboto', 'Microsoft JhengHei', '微軟正黑體', 'Microsoft YaHei', sans-serif;
  color: #263238;
  margin: 0;
  line-height: 1;
}

h1 {
  font-size: 64px;
  line-height: 76px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

h3 {
  font-size: 28px;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
}

p {
  font-family: 'Roboto', 'Microsoft JhengHei', '微軟正黑體', 'Microsoft YaHei', sans-serif;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
}

.upper {
  text-transform: uppercase;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.body1 {
  font-size: 18px;
  line-height: 28px;
}

.body2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.body3 {
  font-size: 14px;
  line-height: 20px;
}

.body4 {
  font-size: 12px;
  line-height: 16px;
}

a.btnMore:hover {
  text-decoration: underline !important;
  opacity: 0.8;
}

a.btnMore:after {
  content: imgurl('common/icons/svg/revamp/24/RightCircle1.svg');
  width: 24px;
  line-height: normal;
  height: 24px;
  display: inline-block;
  transform: translateX(5px) translateY(5px);
  margin: 0px 5px;
}

//Spaces
.margin-140-top {
  margin-top: 140px;
}

.margin-120-top {
  margin-top: 120px;
}

.margin-100-top {
  margin-top: 100px;
}

.margin-90-top {
  margin-top: 90px;
}

.margin-80-top {
  margin-top: 80px;
}

.margin-70-top {
  margin-top: 70px;
}

.margin-60-top {
  margin-top: 60px;
}

.margin-50-top {
  margin-top: 50px;
}

.margin-40-top {
  margin-top: 35px;
}

.margin-30-top {
  margin-top: 30px;
}

.margin-20-top {
  margin-top: 20px;
}

.margin-15-top {
  margin-top: 15px;
}

.margin-10-top {
  margin-top: 10px;
}

.margin-5-top {
  margin-top: 5px;
}

.margin-0-top {
  margin-top: 0px;
}

.margin-160-bottom {
  margin-bottom: 160px;
}

.margin-140-bottom {
  margin-bottom: 140px;
}

.margin-120-bottom {
  margin-bottom: 120px;
}

.margin-110-bottom {
  margin-bottom: 110px;
}

.margin-100-bottom {
  margin-bottom: 100px;
}

.margin-90-bottom {
  margin-bottom: 90px;
}

.margin-80-bottom {
  margin-bottom: 80px;
}

.margin-70-bottom {
  margin-bottom: 70px;
}

.margin-60-bottom {
  margin-bottom: 60px;
}

.margin-50-bottom {
  margin-bottom: 50px;
}

.margin-45-bottom {
  margin-bottom: 45px;
}

.margin-40-bottom {
  margin-bottom: 40px;
}

.margin-32-bottom {
  margin-bottom: 32px;
}

.margin-30-bottom {
  margin-bottom: 30px;
}

.margin-25-bottom {
  margin-bottom: 25px;
}

.margin-20-bottom {
  margin-bottom: 20px;
}

.margin-10-bottom {
  margin-bottom: 10px;
}

.margin-5-bottom {
  margin-bottom: 5px;
}

.margin-0-bottom {
  margin-bottom: 0;
}

/* Bootstrap icons overwrite */
@mixin bi-di-icons($icon-filename) {
  .bi-di-#{$icon-filename}::before {
    content: imgurl('common/icons/svg/revamp/24/#{$icon-filename}.svg');
  }
}

/* Golbal 3rem width css */
.globalWidth {
  @media screen and (min-width: 992px) {
    padding: 0 3rem;
  }
}

.maxWidthContainer {
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
}

/* static page */
.staticPage{
  @media screen and (min-width: 992px) {
    margin-bottom: 150px;
  }
  /* .sec-intro{
    @media screen and (min-width: 992px) {
      margin-top: 75px;
    }
  } */
}

.descContent {
  padding: 10%;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
}

/* storyDetailPage */
.storyDetailPage {
  .storyDetailContainer {
    max-width: 1720px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .maxWidthContainer {
    max-width: 1720px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .paginationDiv{
    @media screen and (max-width:991px) {
      width:100%;
      display: flex;
      justify-content: center;
    }
  }

  .thumbnailCol {
    padding: 0;
  }

  .thumbnailColDiv {
    position: relative;
  }

  .thumbnailColDivImg {
/*     width: 100%; */
    height: 550px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .thumbnailColh1Div {
    position: absolute;
    top: 40px;
    left: 45px;
    @media screen and (max-width:991px){
      left: 15px;
    }
  }

  .thumbnailColh1Div h2 {
    color: white;
    background: #e06527;
    width: fit-content;
    display: inline-block;
    padding: 5px 10px;
    @media screen and (max-width:991px){
      font-size: 28px;
      line-height: 36px;
    }
  }

  .descContentRow {
    margin-top: 85px;
    margin-bottom: 50px;
    padding: 0 45px;
    display: flex;
    column-gap: 44px;

    .column-left {
      width: 40%;
    }

    .column-right {
      max-width: 900px;
      width: 60%;
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
      margin-top: 24px;
      padding: 0 15px;

      .column-left,
      .column-right {
        width: 100%;
      }

      .column-right {
        margin-top: 36px;
        h3 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }

  .descContentRowh5 {
    color: white;
    background: #e06527;
    display: inline-flex;
    padding: 5px 10px;
  }

  .descContentRowh1 {
    margin: 12px 0;
  }

  .descContentRowh4 {
    color: white;
    background: black;
    display: inline-flex;
    padding: 5px 10px;
  }

  .abstract-image {
    margin-top: 125px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
      margin-top: 36px;
    }
    img{
      max-height: 680px;
    }
  }

  .abstract-caption {
    font-size: 16px;
    margin-top: 24px;
    line-height: 24px;
  }



  .story-video-player-trigger {
    display: flex;
    position: relative;
    cursor: pointer;
    margin-top: 44px;

    &::before {
      background-image: imgurl('common/icons/svg/revamp/24/PlayCircle.svg');
      content: ' ';
      background-size: 90px 90px;
      width: 90px;
      height: 90px;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background-color: #000;
      top: 0;
      left: 0;
      opacity: 0.5;
    }
  }

  .image-slider {
    max-width: 884px;
    width: 80%;
    margin: 80px 0px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 50px auto;
      padding: 0 15px;
    }

    &-container {
      @media screen and (max-width: 767px) {
        overflow: hidden;
      }
    }

    .image-slider-content {
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        max-height: 589px;
        height: 80vw;
        object-fit: contain;
        object-position: left;
      }

      .caption {
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
      }
    }

    .swiper-container {
      overflow: visible;
    }

    .swiper-pagination {
      position: relative;
      bottom: 0;
      display: flex;
      margin-top: 20px;
      width: fit-content;
      flex-wrap: wrap;

      .swiper-pagination-bullet {
        width: inherit !important;
        height: inherit !important;
        background: transparent !important;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        margin-left: 0 !important;
        margin-right: 16px !important;
        font-weight: 600;

        &-active {
          background: transparent !important;
          color: #e06527!important;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .main-content {
    max-width: 900px;
    width: 60%;
    margin-left: auto;
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 15px;
      margin-left: 0;
      margin-bottom: 50px;
    }
  }

  .section4_storyItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }

  .section4_storyItemDiv {
    position: relative;
    max-width: 100%;
  }

  .section4_storyItemImg {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
  }

  .section4_storyItemCategory {
    background: #e06527;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .section4_storyItemTitle {
    background: #e06527;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
  }

  .section4_storyItemCompanyName {
    background: black;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
  }

  .section4_storyBox {
    padding: 20px 45px;
    margin-bottom: 80px;
    background-size: cover;
  }

  .section4_storyBoxDiv,
  .section4_happeningsBoxDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section4_storyTitle {
    margin-bottom: 20px;
  }

  .section4_storyBoxSwiperDiv{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .section4_storyTitleBox {
    display: flex;
  }

  .section4_storyTitleBoxLabel {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
    margin-right: 10px;
  }

  @media (max-width: 1200px) {
    .descContentRowh1 {
      font-size: 48px;
      line-height: 64px;
    }
  }

  @media (max-width: 991px) {
    .descContentRowh1 {
      font-size: 36px;
      line-height: 48px;
    }
    .thumbnailColDivImg {
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    .section4_storyItem {
      margin-bottom: 5% !important;
    }

    .section4_storyTitle {
      margin-bottom: 5% !important;
    }

    .section4_storyBox {
      padding: 4% !important;
    }
  }

  @media (max-width: 576px) {
    .section4_storyBoxDiv,
    .section4_happeningsBoxDiv {
      flex-direction: column;
      align-items: start !important;
      margin-bottom: 5%;
    }

    .descContentRow {
      padding: 0 15px;
    }

    .descContentRowCol h3 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 20px;
    }

    .thumbnailColh1Div {
      display: none;
    }

    .thumbnailColDivImg {
      height: 300px;
    }

    .descContentRowh4 {
      font-size: 16px;
    }

    .descContentRowh1 {
      font-size: 28px;
      line-height: 42px;
    }
  }
}

.fancybox__content.player-wrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  background: #2a2a2d;
  max-height: 100%;
}

/* happeningsModal */
.happeningsModal {
  .section4_eventSupImgBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .modalLeftBoxBottom {
    margin-bottom: 5%;
  }

  .noPadding{
    padding: 0;
  }

  .modalRightBoxTitle {
    margin-bottom: 2%;
  }

  .modalLeftBoxImg {
    width: 60%;
  }

  .modalLeftBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .section4_eventSupBox {
      margin-top: 10px;
    }

    .modalLeftBoxBottom {
      margin-top: 10px;
    }
  }

  .modalDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .modalSpan {
    display: block;
    width: 100%;
  }

  @media (max-width: 1200px) {
    .modalLeftBoxImg {
      width: 100%;
    }
  }
}

/* searchBar */
.searchBarDivBox {
  position: sticky;
  top: 246px;
  z-index: 99;
  background: white;
  @media screen and (max-width: 991px) {
    top: 5px;
  }
  .searchBarDiv{
    padding: 0;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    background: white;
    z-index: 10;
    width: 99.9%;
    top: -144px;
    @media screen and (max-width: 1720px) {
      padding: 0;
    }
    @media screen and (max-width: 548px) {
      top: -90px;
    }
  }
  .searchBarEvent {
    position: sticky;
    width: 100%;
    top: -144px;
    background: white;
    margin: 0;
  }

  .searchBarBox {
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 991px) {
      padding: 0.5% 1%;
    }
  }

  .borderLeft {
    border-left: 0;
  }

  .searchBarBox3 {
    justify-content: end;
  }

  .icon {
    margin-left: 15px;
  }

  .searchBox {
    border: none;
    outline: none;
    box-shadow: none;
    text-align: right;
    width: 100%;
    color: black;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    height: fit-content;
  }

  .searchBox::placeholder {
    color: black;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    @media screen and (max-width: 991px) {
      color: #00000066;
    }
  }
  .searchBoxDiv {
    width: 100%;
  }

  .searchIcon:hover {
    cursor: pointer;
  }
  .tagBoxCol {
    display: flex;
  }

  .dropdownBox {
    background: #abbed1;
    border: 1px solid #000;
    border-top: 0;
    height: 110px;
    padding: 1% 1.5%;
    display: flex;
    flex-direction: column;
    z-index: 12;
  }
  .dropdownBox div h4 {
    display: inline-block;
    margin-bottom: 20px;
  }
  .dropdownBox div h4:hover {
    background: #000;
    color: white;
    cursor: pointer;
    padding: 0px 3px;
  }

  .dropdownToggleBox {
    cursor: pointer;
    background: white;

    &.blue {
      background: #abbed1;
    }
  }
  .dateRangePickerDiv {
    display: flex;
    justify-content: center;
    height: 0;
    position: absolute;
    top: 46px;
    left: 0.5px;
  }

  .dateRangePickerRight {
    background: white;
    width: 140px;
    display: flex;
    padding-top: 41px;
    height: 418px;
    background: white;
    border-right: #abbed1 1px solid;
    border-bottom: #abbed1 1px solid;
    z-index: 999;
  }

  .rdrDayNumber {
    font-weight: 400;
  }

  .rdrMonthName {
    text-align: center;
    color: #000000;
  }

  .rdrWeekDay {
    font-weight: 600;
    color: black;
  }

  .dateRangePickerBox {
    display: inline-block;
    border: 1px solid #ccc;
    height: fit-content;
    width: fit-content;
    padding: 5px 10px;
    line-height: 1.5;
    font-size: 12px;
    margin-right: 4px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }

    &.applyBox {
      background: black;
      color: white;
      border: 1px solid black;
    }

    &.applyBox:hover {
      background: #d93b84;
      border: #d93b84 1px solid;
    }
  }

  .rdrDateDisplayWrapper {
    background-color: white;
    z-index: 999;
  }

  .rdrDayToday .rdrDayNumber span {
    &:after {
      background: #d93b84;
    }
  }

  .rdrCalendarWrapper {
    height: 418px;
    background: white;
    border-bottom: #abbed1 1px solid;
    border-left: #abbed1 1px solid;
    padding: 30px;
    z-index: 99;
  }

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    padding-top: 72px;
    width: 664px;
    position: absolute;
  }

  .eventTag {
    color: white;
    background: black;
    padding: 5px 10px;
    width: fit-content;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  @media (max-width: 991px) {
    position: relative;
    .borderRight {
      border-right: 1px black solid !important;
    }
    .searchBarDiv {
      position: absolute;
      width: 98%;
      bottom: 73px;
      margin-left: 1%;
      border: 1px solid black;
      border-bottom: 0px;
    }

    .searchBarEvent {
      position: static;
      width: 100%;
      order: 1;
    }

    .searchBarBox {
      border: 0;
      justify-content: flex-start;
    }
    .dropdownToggleBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .dropdownToggleBox,
    .searchBarBox3 {
      border-bottom: 1px solid black;
    }

    .searchBox {
      text-align: left;
      padding: 0;
    }

    .dropdownBox {
      border: 1px solid black;
      border-top: 0;
      justify-content: space-evenly;
      position: absolute;
      bottom: -110px;
      width: 100%;
    }
    .dropdownBoxDiv h4 {
      margin-bottom: 0 !important;
    }

    .tagBox {
      padding: 1% 2%;
    }
  }
  @media (max-width: 991px) {
    .rdrMonthAndYearWrapper {
      width: 332px;
    }
    .dateRangePickerDiv {
      top: 35px;
      left: 0px;
    }
  }

  @media (max-width: 768px) {
    .rdrMonthAndYearWrapper {
      width: 332px;
    }
  }

  @media (max-width: 548px) {
    .dateRangePickerDiv {
      flex-direction: column-reverse;
      top: 224px;
    }

    .rdrMonthsHorizontal {
      width: 100%;
    }

    .rdrCalendarWrapper {
      position: relative;
      border: 1px solid #abbed1;
    }

    .rdrDateDisplayWrapper {
      width: 332px;
    }

    .dateRangePickerRight {
      width: 100%;
      z-index: 99;
      padding: 0;
      display: flex;
      justify-content: space-evenly;
      height: fit-content;
      position: absolute;
      top: 160px;
      border-left: 1px solid #abbed1;
      padding-bottom: 20px;
    }

    .searchBarBox3 {
      z-index: 50;
    }
  }
  @media (max-width: 480px) {
    .dateRangePickerDiv {
      flex-direction: column-reverse;
      top: 223px;
    }
  }
}

/* happeningsMainPageHeadlineBox */
.happeningsMainPageHeadlineBox {
  .headlineBoxRow {
    margin: 0 !important;
  }

  .descDiv{
    min-height: 300px;
    height: 100%;
    @media screen and (max-width:991px) {
      min-height: 210px;
    }
  }

  .headlineBox {
    padding: 0 !important;
  }

  .headlineLeftbox {
    background: #d93b84;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .headlineLeftbox h1 {
    color: white;
    padding-left: 10%;
  }

  @media (max-width: 1200px) {
    .headlineLeftbox h1 {
      font-size: 48px;
    }
  }

  @media (max-width: 992px) {
    .headlineLeftbox {
      min-height: 170px;
    }

    .headlineLeftbox h1 {
      font-size: 36px;
    }
  }

  @media (max-width: 480px) {
    .headlineLeftbox {
      min-height: 100px;
    }

    .headlineLeftbox h1 {
      font-size: 28px;
    }
  }
}

/*happeningsPage FilteredArea */
.filteredAreaHappenings {
  height: 120px;
  background: #333333;
  position: sticky;
  top: 126px;
  z-index: 98;
  @media screen and (max-width:991px){
    position: static;
  }

  .tagBoxDiv {
    display: flex;
    align-items: end;
    height: 100%;
    @media screen and (max-width: 991px) {
      padding: 0 1%;
    }
  }
  .tagBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;
    @media screen and (max-width: 991px) {
      padding-bottom: 10px;
    }
  }
  .eventTag {
    color: white;
  }
  .btnReset {
    border-radius: 8px;
    background: #fdbc11;
    display: flex;
    padding: 0 10px;
    border: 0;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin: 0 0.5rem;
    .restartIcon {
      margin-right: 12px;
    }
    h6 {
      font-weight: normal;
      white-space: nowrap;
    }
  }

  .btnReset:hover {
    h6 {
      font-weight: normal;
    }
  }
  .tagBoxCol {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    h2 {
      color: white;
      margin: 0 0.5rem;
      @media screen and (max-width: 991px) {
        margin: 0 0.25rem;
        font-size: 24px;
        line-height: 36px;
      }
      @media screen and (max-width: 730px) {
        margin: 0 0.125rem;
        font-size: 20px;
        line-height: 28px;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/* happeningsPageEventItems */
.happeningsEventsList {
  .section4_happeningsBox {
    padding: 40px 10px;
  }

  .supBox,
  .timeBox,
  .locationBox,
  .supBoxCol:nth-child(2) {
    padding: 0;
  }

  .section4_happeningsLabelBox {
    display: flex;
  }

  .section4_happeningsLabel {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
    margin-right: 10px;
  }

  .section4_eventBottomBorder {
    border-bottom: 1px solid #898989;
    margin-bottom: 5%;
  }

  .section4_eventImgBox {
    margin-bottom: 5%;
  }

  .section4_eventImg {
    width: 100%;
    height: 100%;
  }

  .section4_eventRightBox {
    height: 520px;
  }

  .section4_eventRightBoxDiv {
    display: flex;
    flex-direction: column;
  }

  .section4_eventTagBox {
    display: flex;
    margin-bottom: 2%;
    flex-wrap: wrap;
  }

  .section4_eventName {
    margin-bottom: 2%;
  }

  .section4_eventRegisterDiv {
    display: flex;
    margin-bottom: 2%;
    align-items: center;
    margin-bottom: 7%;
  }

  .section4_eventRegisterBox {
    display: flex;
    color: white;
    background: #f46e0f;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .section4_eventRegisterBoxClosed {
    color: white;
    background: #f46e0f;
    margin-right: 15px;
    padding: 1%;
  }

  .section4_eventRegister {
    margin-right: 20px;
  }

  .section4_eventInfoBox {
    display: flex;
    cursor: pointer;
  }

  .section4_eventInfo {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
  }

  .section4_eventSupBox {
    margin-bottom: 5%;
  }

  .section4_eventSupImgBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .section4_eventSupImgDiv {
    margin-right: 3%;
  }

  .section4_eventSupImg {
    max-width: 160px;
    max-height: 80px;
  }

  .section4_eventBottomBox {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 5%;
  }

  .section4_eventTimeBoxDiv {
    padding-right: 15px;
    padding-left: 0;
  }

  .section4_eventTimeBox {
    display: flex;
    flex-direction: column;
  }

  .section4_eventTiime {
    text-wrap: wrap;
  }

  .section4_eventTag {
    color: white;
    background: black;
    padding: 1% 2%;
    margin-right: 2%;
    margin-bottom: 2%;
  }

  .wordingCenter {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .wordingNoMatches h2 {
    color: #717171;
    font-weight: 600;
  }

  .loadMoreBtn {
    color: white;
    background: #000;
    width: 200px;
    height: 50px;
    border: 0px;
    padding: 5px 22px;
    margin-top: 5%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    &:hover{
      cursor: pointer;
      background: #fdbc11;
      color: black;
    }
  }

  .section4_happeningsInfoBtn {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }

  .timeBox,
  .locationBox h6 {
    text-wrap: nowrap;
  }

  .locationBox span {
    display: block;
  }

  @media (max-width: 768px) {
    .section4_happeningsBox {
      padding: 4% !important;
    }
  }

  @media (max-width: 576px) {
    .section4_eventRegisterDiv {
      order: 1;
    }

    .section4_eventBottomBox {
      flex-direction: column;
    }

    .section4_eventTimeBoxDiv {
      display: flex;
      width: 100%;
    }

    .timeBox,
    .locationBox {
      padding: 0;
    }

    .section4_eventSupBox {
      display: flex;
    }

    .supBox {
      padding: 0;
    }

    .section4_eventSupImg {
      max-width: 180px;
      max-height: 135px;
    }

    .section4_eventName {
      margin-bottom: 7%;
    }

    .section4_eventRegisterDiv {
      margin-top: 5%;
    }
  }
}

/* Happenings PAGE */
.happeningsPage {
  overflow-x: clip;

  .maxWidthContainer {
    margin-right: auto;
    margin-left: auto;
    max-width: 1720px !important;
  }
}

/* Main PAGE */
.mainPage {
  background-color: #fff;
  overflow-x: hidden;

  .container,
  .main-banner {
    /* margin-bottom: 60px; */
  }

  .maxWidthContainer {
    margin-right: auto;
    margin-left: auto;
    max-width: 1720px !important;
  }

  .section4_storyInfoBtn,
  .section4_showcaseInfoBtn {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }

  .section4_happeningsInfoBtn {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }

  .previousBtn {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .previousBtn:hover {
    cursor: pointer;
  }

  .nextBtn:hover {
    cursor: pointer;
  }

  .section4_eventTag {
    color: white;
    background: black;
    padding: 1% 2%;
    margin-right: 2%;
  }

  .circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
  }

  .circle-container-left {
    margin-right: 10px;
  }

  .section1-item1-Row {
    margin: 0;
  }

  .section1-marquee-color {
    background: black;
  }

  .fancyboxContent {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }

  .section2-img {
    height: 600px;
    width: 100%;
  }

  .section2-btn {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 40%;
    color: white;
  }

  .section2-btn h3 {
    color: white;
  }

  .section2Content {
    max-height: 600px;
  }

  .section3-item3 {
    position: relative;
  }

  .section3-img {
    width: 100%;
    max-height: 626px;
    object-fit: cover;
  }

  .section3-wordingBox {
    background: #e06527;
    width: 40%;
    position: absolute;
    padding: 2%;
    bottom: 20%;
    right: 0px;
  }

  .section4_storyItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }

  .section4_storyItemDiv {
    position: relative;
    max-width: 100%;
  }

  .section4_storyItemImg {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
  }

  .section4_storyBoxSwiperDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .section4_storyItemCategory {
    background: #e06527;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .section4_storyItemTitle {
    background: #e06527;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
  }

  .section4_storyItemCompanyName {
    background: black;
    color: white;
    padding: 1% 1.5%;
    width: fit-content;
  }

  .section4_storyBox {
    padding: 2% 2%;
    background-size: cover;
    background-position: bottom;
  }

  .section4_storyBoxDiv,
  .section4_happeningsBoxDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
  }

  .section4_storyTitleBox {
    display: flex;
  }

  .section4_storyTitleBoxLabel {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
    margin-right: 10px;
  }

  .showcaseItemLeft {
    padding: 3% 0;

    @media screen and (min-width: 992px) {
      padding: 3% 15% 3% 0;
    }
  }

  .section4_showcaseTitleBox {
    margin-bottom: 2%;
  }

  .section4_showcaseTitle {
    color: white;
    background: black;
    display: inline;
    padding: 0 1%;

    @media screen and (max-width: 768px) {
      font-size: 36px;
      line-height: normal;
    }

    @media screen and (max-width: 1380px) {
      font-size: 48px;
      line-height: normal;
    }
  }

  .section4_showcaseDescription {
    margin-bottom: 4%;
  }

  .section4_showcaseLabelBox {
    display: flex;
  }

  .section4_showcaseLabel {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
    margin-right: 10px;
  }

  .section3_showcaseBox {
    display: flex;
    flex-direction: column;
  }

  .section3_showcaseBoxDiv {
    width: 100%;
    height: 100%;
  }

  .section3_showcaseBoxImg {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 1;
    object-position: center;
  }

  .section3_showcaseBoxLocation {
    width: fit-content;
    color: white;
    background: black;
    padding: 0 4%;
    margin-top: 5%;
  }

  .circleButton {
    margin-top: 2%;
    display: flex;
  }

  .section4_showcaseBox {
    padding: 2%;
  }

  .section4_showcaseBox_relative {
    width: 100%;
    max-height: 600px;
  }

  .section4_showcaseBox_absolute {
    width: 100%;
  }

  .section4_happeningsBox {
    padding: 2% 2% 4%;
  }

  .section4_storyBoxDiv,
  .section4_happeningsBoxDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section4_happeningsLabelBox {
    display: flex;
  }

  .section4_happeningsLabel {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
    margin-right: 10px;
  }

  .section4_eventBottomBorder {
    border-bottom: 1px solid #898989;
    margin-bottom: 5%;
  }

  .section4_eventImgBox {
    margin-bottom: 5%;
  }

  .section4_eventImg {
    width: 100%;
    height: 100%;
  }

  .section4_eventRightBox {
    height: 520px;
  }

  .section4_eventRightBoxDiv {
    display: flex;
    flex-direction: column;
  }

  .section4_eventTagBox {
    display: flex;
    margin-bottom: 2%;
  }

  .section4_eventName {
    margin-bottom: 2%;
  }

  .section4_eventRegisterDiv {
    display: flex;
    margin-bottom: 2%;
    align-items: center;
    margin-bottom: 7%;
  }

  .section4_eventRegisterBox {
    display: flex;
    color: white;
    background: #f46e0f;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .section4_eventRegisterBoxClosed {
    color: white;
    background: #f46e0f;
    margin-right: 15px;
    padding: 1%;
  }

  .section4_eventRegister {
    margin-right: 20px;
  }

  .section4_eventInfoBox {
    display: flex;
    cursor: pointer;
  }

  .section4_eventInfo {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: solid;
  }

  .section4_eventSupBox {
    margin-bottom: 5%;
  }

  .noPadding{
    padding: 0;
  }

  .section4_eventSupImgBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .section4_eventSupImgDiv {
    margin-right: 3%;
  }

  .section4_eventSupImg {
    max-width: 160px;
    max-height: 80px;
  }

  .section4_eventBottomBox {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 5%;
  }

  .section4_eventTimeBoxDiv {
    padding-right: 15px;
    padding-left: 0;
  }

  .section4_eventTimeBox {
    display: flex;
    flex-direction: column;
  }

  .section4_eventTiime {
    text-wrap: wrap;
  }

  .section1-item1 {
    padding: 0;
  }

  .section1_contentLeftDiv {
    height: 100%;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }

    .section1-item1 {
      order: 2;
    }

    .section1-item2 {
      order: 1;
    }

    .section2-img {
      height: 300px !important;
    }

    .section2-btn h3 {
      display: none;
    }

    .section2-btn img {
      width: 80px;
    }

    .section3-wordingBox {
      width: 100% !important;
      display: block !important;
      position: static !important;
    }

    .section4_storyItem {
      margin-bottom: 5% !important;
    }

    .section4_storyTitle,
    .section4_happeningsTitle {
      margin-bottom: 5% !important;
    }

    .section4_showcaseBox,
    .section4_happeningsBox,
    .section4_storyBox {
      padding: 4% !important;
    }

    .section3_showcaseBox {
      margin: 5% 0 !important;
    }
  }

  @media (max-width: 576px) {
    .section3_showcaseBoxDiv {
      width: 100% !important;
      height: 100% !important;
    }

    .section3_showcaseItemBox1,
    .section3_showcaseItemBox2 {
      display: none;
    }

    .section4_happeningsBoxDiv {
      flex-direction: column;
      align-items: start !important;
      margin-bottom: 10%;
    }

    .section4_storyBoxDiv {
      flex-direction: column;
      align-items: start !important;
      margin-bottom: 10%;
    }
  }
}

@include bi-di-icons(Expand1);
@include bi-di-icons(Right);
@include bi-di-icons(Eye);
@include bi-di-icons(Right1);
@include bi-di-icons(AddUser);
@include bi-di-icons(Filter);
@include bi-di-icons(RightCircle);
@include bi-di-icons(Bell);
@include bi-di-icons(Filter1);
@include bi-di-icons(RightCircle1);
@include bi-di-icons(Burger);
@include bi-di-icons(Hide);
@include bi-di-icons(RightSquare);
@include bi-di-icons(Calendar);
@include bi-di-icons(Home);
@include bi-di-icons(RightSquare1);
@include bi-di-icons(Camera);
@include bi-di-icons(Image);
@include bi-di-icons(Save);
@include bi-di-icons(Category);
@include bi-di-icons(InfoSquare);
@include bi-di-icons(Scanner);
@include bi-di-icons(Check);
@include bi-di-icons(Layers);
@include bi-di-icons(Search);
@include bi-di-icons(CheckSquare);
@include bi-di-icons(Left);
@include bi-di-icons(Send);
@include bi-di-icons(Close);
@include bi-di-icons(Left1);
@include bi-di-icons(Settings);
@include bi-di-icons(Collapse);
@include bi-di-icons(LeftCircle);
@include bi-di-icons(Share);
@include bi-di-icons(Copy);
@include bi-di-icons(LeftCircle1);
@include bi-di-icons(Strech);
@include bi-di-icons(Danger);
@include bi-di-icons(LeftSquare);
@include bi-di-icons(Swap);
@include bi-di-icons(Document);
@include bi-di-icons(LeftSquare1);
@include bi-di-icons(Swap2);
@include bi-di-icons(Document2);
@include bi-di-icons(Link);
@include bi-di-icons(Swap3);
@include bi-di-icons(DocumentAdd);
@include bi-di-icons(Link1);
@include bi-di-icons(DocumentDelite);
@include bi-di-icons(Loading);
@include bi-di-icons(Trash);
@include bi-di-icons(Down);
@include bi-di-icons(Location);
@include bi-di-icons(Up);
@include bi-di-icons(Down1);
@include bi-di-icons(Lock);
@include bi-di-icons(Up1);
@include bi-di-icons(DownCircle);
@include bi-di-icons(LockCheck);
@include bi-di-icons(UpCircle);
@include bi-di-icons(DownCircle1);
@include bi-di-icons(LockOpen);
@include bi-di-icons(UpCircle1);
@include bi-di-icons(DownLeft);
@include bi-di-icons(LockX);
@include bi-di-icons(UpLeft);
@include bi-di-icons(DownLeftCircle);
@include bi-di-icons(Login);
@include bi-di-icons(UpLeftCircle);
@include bi-di-icons(Download2);
@include bi-di-icons(Logout);
@include bi-di-icons(UpRight);
@include bi-di-icons(DownRight);
@include bi-di-icons(Logout1);
@include bi-di-icons(UpRightCircle);
@include bi-di-icons(DownRightCircle);
@include bi-di-icons(Mail);
@include bi-di-icons(UpSquare);
@include bi-di-icons(DownSquare);
@include bi-di-icons(Minus);
@include bi-di-icons(UpSquare1);
@include bi-di-icons(DownSquare1);
@include bi-di-icons(MoreCircle);
@include bi-di-icons(User);
@include bi-di-icons(Edit1);
@include bi-di-icons(MoreSquare);
@include bi-di-icons(Users);
@include bi-di-icons(Edit2);
@include bi-di-icons(Paperclip);
@include bi-di-icons(X);
@include bi-di-icons(Expand);
@include bi-di-icons(Plus);
