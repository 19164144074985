@charset "utf-8";
// Write your custom style or overwrite default.scss
// Override Detault style
// DO NOT PUSH this file

.spg-desc:empty {
  transition: all 0.5s ease;
}

header {
  .navbar .mainLogo {
    height: 63px;
    width: 52%;
    @media screen and (min-width: 575px){
      width: 90%;
    }
    @media screen and (min-width: 991px){
      width: 100%;
    }
  }

  @media screen and (max-width: $max-w-tb) {
    .header__top .languages:before {
      display: none;
    }
  }

  .container {
    padding: 0;
  }
}

// Jumbotron
.swiper-slide-active .main-banner__title {
  font-size: 63px;

  @media screen and (max-width: 1580px) {
    font-size: 56px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
}

// Language Switch
.languages:before {
  background: transparent !important;
}

.languages a {
  background: #231f20;
  font-size: 18px;
  line-height: 24px;
  color: white !important;
  font-weight: 700;
  @media screen and (max-width: 992px) {
    font-size: 20px;
    padding: 4px;
  }
}

.languages a:hover {
  color: #fdbc11 !important;
}

.mbActionNav {
  position: absolute;
  top: 17px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    vertical-align: top;
  }
}

// DesignInspire
.ml-auto .nav-link {
  text-transform: none !important;
}

.header-bar a,
.menuheaderlinksContainer a {
  font-size: 14px;
  color: #000 !important;
  font-weight: normal;
}

.header-bar a,
.header-bar.actionBtn a {
  font-weight: normal;
}

.siteLink {
  color: #000;
}

// mobile menu toggle hamburger
.navbar-toggler .icon-bar {
  width: 44px;
  height: 4px;
  margin: 8px 0 8px 0;
}

@media screen and (max-width: $max-w-tb) {
  // 991px

  //mobile toggle
  body.openedMenu {
    overflow: hidden;

    .scroll-down {
      display: none;
    }

    .navbar {
      height: 100vh;
      align-items: flex-start;
    }

    .mbActionNav > div {
      margin-right: 17px;
    }

    .menuheaderlinksContainer {
      color: #333;
      text-align: left;

      a {
        font-size: 15px;
      }
    }

    .mbActionBtn {
      width: 100%;
      margin-bottom: 10px;

      .actionBtn {
        display: inline;
        width: 130px;
      }
    }

    .mbMenuFooter {
      left: 2px;
      bottom: 10px;
      display: block;
    }

    nav {
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #fdbc11 transparent;
    }

    nav::-webkit-scrollbar {
      width: 5px;
      height: 100%;
      background-color: transparent;
    }

    nav::-webkit-scrollbar-thumb {
      background: #fdbc11;
    }

    .mainLogo {
      position: sticky;
      z-index: 99;
    }

    .mbActionNav {
      z-index: 99;
    }

    .navbar-collapse {
      padding-top: 120px !important;
      margin-top: -120px;
    }
    /*	
	.rc-menu-submenu-arrow{
		display: none;	
        opacity: 0;		
	}
	

	ul.ct-mobileMenu>li.rc-menu-item>a.nav-link  , ul.ct-mobileMenu>li.rc-menu-submenu>div.rc-menu-submenu-title {
	  font-size: 28px !important ;
      line-height: 36px;
	  font-weight: 600;
	}
	
	li.rc-menu-submenu-open>ul.rc-menu-sub>li>a.nav-link{
	  font-size: 12px ;	
      line-height: 18px;
	  font-weight: 400;
	  color: #333;
	}
	*/

    ul.rc-menu-sub {
      background: #fff;
      li.rc-menu-item {
        padding-left: 2px !important;
      }
    }
  }

  .navbar-collapse {
    align-self: flex-start;
    height: 100%;
  }

  header div.container .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  i.rc-menu-submenu-arrow {
    display: none !important;
  }
  // mobile menu font size
  .ct-mobileMenu.rc-menu-inline:not(.rc-menu-sub) > .rc-menu-item,
  .ct-mobileMenu.rc-menu-inline:not(.rc-menu-sub) > .rc-menu-item .nav-link,
  .ct-mobileMenu.rc-menu-inline:not(.rc-menu-sub) > .rc-menu-submenu > .rc-menu-submenu-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    text-transform: none;
  }

  //hidden header_top
  .header__top {
    display: none;
  }
}
